import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { PusherService } from './services/pusher.service';
import { VersionService } from 'src/app/services/version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public loading: boolean = false;
  public user: any = null;

  constructor(
    private _api: ApiService,
    public _router: Router,
    private _pusher: PusherService,
    private _version: VersionService
  ) {
    console.log('AppComponent constructor');
    this.loaderOnRouteChange();
  }

  async ngOnInit(): Promise<void> {
    this._version.watch();

    console.log(this._version.full_version);

    this.user = await this._api.getUser();
    console.log('AppComponent ngOnInit');
    this._pusher.addDeviceInterest(this.user.pusher_interests);

     navigator.serviceWorker.addEventListener("message", (event) => {
       console.log(event.data.msg, event.data.url);
    });
    let resul = this._pusher.NotificationsObservable;
    console.log('Este es el mensjae re getWorker', resul);
    const channel4Broadcast = new BroadcastChannel('channel4');
    channel4Broadcast.onmessage = (event) => {
      console.log('AppComponent channel4Broadcast event', event);
    }
  }

  ngOnDestroy(): void {
    console.log('AppComponent ngOnDestroy');
  }

  public loaderOnRouteChange(): void {
    this._router.events.subscribe(async (val: any) => {
      if (val instanceof NavigationStart) {
        this.loading = true;
      }

      if (val instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }
}
