import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  lastValueFrom,
  of,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';


export const API_URI = environment.base_uri;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public userEmitter = new ReplaySubject();
  public userObservable = this.userEmitter.asObservable();

  constructor(private _router: Router, private _http: HttpClient) {}

  public logout(): void {
    this.post('api/sanctum/logout', {}).subscribe({
      next: (response: any) => {
        console.log('ApiService logout response', response);
        localStorage.removeItem('token');
        this._router.navigate(['/']).then(() => {
          window.location.href = window.location.href;
        });
      },
      error: (error: any) => {
        console.log('ApiService logout error', error);
        localStorage.removeItem('token');
      },
    });
  }

  public async getPage(): Promise<any> {
    try {
      const response = await lastValueFrom(
        this.get(`api/cms/cms-pages/get?url=${this._router.url}`)
      );
      if (!response.data) this._router.navigate(['/admin/404']);
      return response.data;
    } catch (error) {
      this._router.navigate(['/admin/404']);
      return null;
    }
  }

  public async getUser(): Promise<any> {
    try {
      const user = await lastValueFrom(this.userEmitter);
      if (!user) this.logout();
      return user;
    } catch (error) {
      this.logout();
      return null;
    }
  }

  public async getBreadcrumb(page: any): Promise<any> {
    return !!page && !!page.breadcrumb ? page.breadcrumb.items : [];
  }

  public async getStatWidgets(page: any): Promise<any> {
    return !!page && !!page.stat_widgets ? page.stat_widgets : [];
  }

  /** GET REQUEST */
  public get(url: string): Observable<any> {
    return this._http.get(`${API_URI}/${url}`, this._getOptions());
  }

  getImage(url: string): Observable<any> {
    return this._http.get(`${API_URI}/${url}`, {
      headers: this._getBlobOptions(),
      responseType: 'blob',
    });
  }

  /** POST REQUEST */
  public post(url: string, params: any): Observable<any> {
    return this._http.post(`${API_URI}/${url}`, params, this._getOptions());
  }

  /** PUT REQUEST */
  public put(url: string, params: any): Observable<any> {
    return this._http.put(`${API_URI}/${url}`, params, this._getOptions());
  }

  /** DELETE REQUEST */
  public delete(url: string, id: number): Observable<any> {
    return this._http.delete(`${url}/${id}`, this._getOptions());
  }

  /** HTTP HEADERS */
  private _getOptions() {
    let token: string | null = localStorage.getItem('token');

    let httpOptions = {
      headers: new HttpHeaders({
        observe: 'response',
        responseType: 'json',
        Authorization: 'Bearer ' + token,
      }),
    };
    return httpOptions;
  }

  private _getBlobOptions() {
    let token: string | null = localStorage.getItem('token');

    let httpOptions = {
      Authorization: 'Bearer ' + token,
    };
    return httpOptions;
  }


}
