import { Injectable } from '@angular/core';
import * as PusherPushNotifications from '@pusher/push-notifications-web';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PusherService {
  beamsClient!: PusherPushNotifications.Client;
  notifications: Observable<any[]> | undefined;
  private myNotification = new BehaviorSubject<any>([]);
  myNotification$ = this.myNotification.asObservable();

  public NotificationsObservable = this.getWorker();
  //eventNotifications: any;
  constructor() {
    this.beamsClient = new PusherPushNotifications.Client({
      instanceId: environment.pusher.instanceId,
    });
  }

  async ngOnInit(): Promise<void> {
    // navigator.serviceWorker.addEventListener("message", (event) => {
    //   console.log(event.data.msg, event.data.url);
    // });
  }

  public getWorker()  {
    const channel4Broadcast = new BroadcastChannel('channel4');
    let pusherNotifications: Observable<any[]> | undefined;
    channel4Broadcast.onmessage = (event) => {
      console.log('AppComponent channel4Broadcast event', event);
      pusherNotifications = event.data.notifications;
      this.myNotification.next(event.data.notification)
    };

  }

 /*  public addDeviceInterest(interests: string[]): void {
    interests.forEach((interest: string) => {
      this.beamsClient
        .start()
        .then(() => {this.beamsClient.addDeviceInterest(interest); console.log("esta es la linea 44", )})
        .then(() =>
          console.log(`Successfully registered and subscribed!: ${interest}`)
        )
        .catch(console.error);
        console.log("esta es la linea 49", )
      })
   } */
   public async addDeviceInterest(interests: string[]): Promise<void> {
    await this.beamsClient.start();
    for (const interest of interests) {
      try {
        await this.beamsClient.addDeviceInterest(interest);
        console.log(`Successfully registered and subscribed!: ${interest}`);
      } catch (error) {
        console.error(error);
      }
    }
  }

}
