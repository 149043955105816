import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import * as uuid from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private interval: any = null;
  private new_version: boolean = false;
  private seconds: number = 60000;
  private version: any = null;

  constructor(private _api: ApiService, private _router: Router) {}

  public watch(): void {
    if (!!this.interval) {
      clearInterval(this.interval);
    }

    setTimeout(() => {
      this.getCurrentVersion();
    }, 5000);

    this.interval = setInterval(() => {
      this.getCurrentVersion();
    }, this.seconds);
  }

  public getCurrentVersion(): void {
    let current_url: string = this._router.url;
    console.log('getCurrentVersion current_url', current_url);
    let url: string = `api/app/versions/get-current?app_name=${environment.version.app_name}`;

    this._api.get(url).subscribe({
      next: (response: any) => {
        console.log('VersionService getCurrentVersion response', response);

        this.version = response.data;

        if (!this.version) {
          return;
        }

        this.new_version =
          environment.version.version_code < this.version.version_code;

        if (!!this.new_version) {
          Swal.fire({
            title: `Nueva actualización disponible`,
            html: `${this.version.full_version}`,
            icon: `info`,
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: 'ACTUALIZAR',
            backdrop: true,
            allowOutsideClick: false,
          }).then((result: any) => {
            if (result.isConfirmed) {
              this.forceReload();
            } else if (result.isDenied) {
            }
          });
        }
      },
      error: (error: any) => {
        console.log('getCurrentVersion error', error);
      },
    });
  }

  public forceReload(): void {
    const redirect_url: string = this._router.url;
    const hash: string = uuid.v4();

    localStorage.setItem('redirect_url', redirect_url);
    window.location.href = `/update/${hash}`;
  }

  public get full_version(): string {
    return `VERSIÓN: ${environment.version.version_name} (${environment.version.version_code}) - ${environment.version.release_date}`;
  }
}
