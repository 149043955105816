import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SwalService } from 'src/app/services/swal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(
    private _api: ApiService,
    private _router: Router,
    private _swal: SwalService
  ) {
    console.log('AuthGuard constructor');
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._api.post('api/sanctum/user', {}).pipe(
      map((response: any) => {
        console.log('AuthGuard canLoad response', response);

        let user: any = response.user;

        if (!user) {
          this._router.navigate(['/']);
        }

        this._api.userEmitter.next(user);
        this._api.userEmitter.complete();

        return !!user;
      }),
      catchError((error: any) => {
        console.log('AuthGuard catchError error', error);
        this._api.userEmitter.next(null);
        this._api.userEmitter.complete();
        return of(true);
      })
    );
  }

  // canLoad(
  //   route: Route,
  //   segments: UrlSegment[]
  // ):
  //   | Observable<boolean | UrlTree>
  //   | Promise<boolean | UrlTree>
  //   | boolean
  //   | UrlTree {
  //   return this._api.post('api/sanctum/user', {}).pipe(
  //     map((response: { user: boolean }) => {
  //       console.log('AuthGuard canLoad response', response);

  //       let user: any = response.user;

  //       if (!user) {
  //         this._router.navigate(['/']);
  //       }

  //       console.log('AuthGuard PaymentReportsComponent STEP 1');

  //       setTimeout(() => {
  //         this._api.userEmitter.next(user);
  //         this._api.userEmitter.complete();
  //         console.log('AuthGuard PaymentReportsComponent STEP 2');
  //       }, 2000);

  //       console.log('AuthGuard PaymentReportsComponent STEP 3');

  //       return !!user;
  //     }),
  //     catchError((error: any) => {
  //       console.log('AuthGuard catchError error', error);

  //       this._swal.error(error);

  //       return of(true);
  //     })
  //   );
  // }
}
