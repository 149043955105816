import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SwalService {
  constructor() {}

  public error(error: any): void {
    let status: number = !!error && !!error.status ? error.status : null;

    switch (status) {
      case 401:
        this.error401();
        break;

      case 422:
        this.error422(error);
        break;

      case 503:
        this.error503();
        break;

      default:
        this.unknownError();
        break;
    }
  }

  public error401(): void {
    swal.fire({
      title: '¡Error!',
      text: 'Los datos ingresados son incorrectos.',
      icon: 'error',
    });
  }

  public error422(error: any): void {
    console.log('SwalService error422 error', error);

    let message = '';

    let email =
      !!error.error && !!error.error.errors && !!error.error.errors['email']
        ? error.error.errors['email']
        : null;

    if (!!email) {
      message = 'El formato del campo Email es incorrecto';
    } else {
      message = 'Los datos ingresados son incorrectos';
    }

    swal.fire({
      title: '¡Error!',
      text: message,
      icon: 'error',
    });
  }

  public error503(): void {
    swal.fire({
      title: 'Sitio en mantenimiento',
      text: 'Nos encontramos realizando mejoras en la plataforma.',
      icon: 'warning',
    });
  }

  public unknownError(): void {
    swal.fire({
      title: '¡Error!',
      text: 'Ha ocurrido un error desconocido. Favor contacte al administrador del sistema.',
      icon: 'error',
    });
  }
}
