import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { GuestGuard } from 'src/app/guards/guest.guard';

const routes: Routes = [
  {
    path: 'admin',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('src/app/modules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'update',
    canLoad: [],
    loadChildren: () =>
      import('src/app/modules/update/update.module').then(
        (m) => m.UpdateModule
      ),
  },
  {
    path: 'update/:hash',
    canLoad: [],
    loadChildren: () =>
      import('src/app/modules/update/update.module').then(
        (m) => m.UpdateModule
      ),
  },
  {
    path: '',
    canLoad: [GuestGuard],
    loadChildren: () =>
      import('src/app/modules/admin/pages/login-page/login-page.module').then(
        (m) => m.LoginPageModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
